import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default/DefaultTemplate.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In the meantime, explore our `}<a parentName="p" {...{
        "href": "/services"
      }}>{`services`}</a>{` or `}<a parentName="p" {...{
        "href": "/news"
      }}>{`read the latest`}</a>{` on structural engineering and HH Consulting.`}</p>
    <p>{`Want to get your project going right away? Call us at: `}<Link to="tel:702-844-8382" mdxType="Link">{`702.844.8382`}</Link></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      